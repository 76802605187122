import React from "react"
import ProductsHero from "../components/Products/ProductsHero"

import HraContact from "../components/Hra/HraContact"

import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import guideQsehra from "../assets/guide-salusions.svg"
import guideIchra from "../assets/guide-to-ichra.svg"
import qsehraVsIchra from "../assets/ichravsqsehra-poster.svg"
import { Helmet } from "react-helmet-async"

export default function QsehraVsIchra() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "QSEHRA vs. ICHRA",
		"description": "Learn the key differences between ICHRAs and QSEHRAs and discover which health reimbursement plan is best for your business.",
		"thumbnailUrl": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
		"uploadDate": "2024-10-05T14:30:00+00:00",
		"duration": "PT12M8S",
		"contentUrl": "https://www.youtube.com/embed/2f6mAMDRnSc?si=zO81hNyHZGOkrluN"
	}
	return (
		<>
			<Helmet>
				<title>Salusion - ICHRA vs. QSEHRA</title>
				<meta name="title" content="ICHRA vs. QSEHRA: Which HRA is Best for Your Small Business? | Salusion" />
				<meta name="description" content="Learn the key differences between ICHRA and QSEHRA. Discover which health reimbursement plan is best for your business by comparing employee eligibility, insurance requirements, and flexibility." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<ProductsHero
				title="ICHRA vs QSEHRA"
				subtitle="Learn the key differences between ICHRAs and QSEHRAs and discover which health reimbursement plan is best for your business."
				video="https://www.youtube.com/embed/2f6mAMDRnSc?si=zO81hNyHZGOkrluN"
				buttonsHide={true}
				poster={qsehraVsIchra}
				autoPlay={true}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>

				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="A health benefit for all employees offered by small companies"
					pageLink="/product/qsehra/video-guide"
				/>
			</ResourceCenter>
			<HraContact />
		</>
	)
}
