import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/qsehra-rules.svg"
import guideQsehra from "../assets/guide-salusions.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"

export default function QsehraVideoGuide() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "QSEHRA Video Guide",
		"description": "Watch Salusion’s video guide to learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly.",
		"thumbnailUrl": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
		"uploadDate": "2024-10-05T14:30:00+00:00",
		"duration": "PT9M44S",
		"contentUrl": "https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
	}

	return (
		<>
			<Helmet>
				<title>Salusion - QSEHRA Video</title>
				<meta name="title" content="QSEHRA Video Guide: Everything You Need to Create and Administer Your QSEHRA | Salusion" />
				<meta name="description" content="Watch Salusion’s video guide to learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="QSEHRA Video Guide"
				subtitle="Watch Salusion’s video guide to learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly."
				poster={guideQsehra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
				autoPlay={true}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
			<PriceComparison title="QSEHRA Price Comparison" dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} />
			<div className="products-hra-wrapper">
				<HraContact />
			</div>
		</>
	)
}
