import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import HraContact from "../components/Hra/HraContact"

import reimbursementOptions from "../assets/reimbursement-options.svg"
import ichraRules from "../assets/ichraRules.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

export default function ReimbursementOptions() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "ICHRA Reimbursement Options",
		"description": "Explore Salusion’s ICHRA reimbursement options: ACH payments and premium payments. Simplify ICHRA administration with flexible methods to suit your business needs.",
		"thumbnailUrl": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
		"uploadDate": "2024-10-05T14:30:00+00:00",
		"contentUrl": "https://www.youtube.com/embed/eUbT2hw6Ark?si=uHs0hXI4W37s__Ff"
	}

	return (
		<>
			<Helmet>
				<title>Salusion - ICHRA Reimbursement Options</title>
				<meta name="title" content="ICHRA Reimbursement Options: ACH & Premium Payments | Salusion" />
				<meta name="description" content="Explore Salusion’s ICHRA reimbursement options: ACH payments and premium payments. Simplify ICHRA administration with flexible methods to suit your business needs." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>

			</Helmet>
			<ProductsHero
				title="ICHRA Reimbursement Options"
				subtitle="Explore Salusion’s ICHRA reimbursement options: ACH payments and premium payments. Simplify ICHRA administration with flexible methods to suit your business needs."
				video="https://www.youtube.com/embed/eUbT2hw6Ark?si=uHs0hXI4W37s__Ff?autoplay=1"
				buttonsHide={true}
				poster={reimbursementOptions}
				autoPlay={true}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					resourceImg={ichraRules}
					pageLink="/product/ichra/rules-and-regulations"
				/>
				<ResourceCenterVideo
					title="ICHRA topics for large employers"
					resourceImg={employees}
					pageLink="/product/ichra/large-business"
				/>
				<ResourceCenterVideo
					title="Discover how much you can save with an ACA-compliant ICHRA"
					resourceImg={ichraCalculator}
					externalLink="product/ichra/aca-compliant-calculator"
				/>
				{/* <ResourceCenterVideo
					title="Find a broker in your state that can help your employee find insurance"
					resourceImg={findBroker}
					pageLink="/product/ichra/find-a-broker"
				/>
				<ResourceCenterVideo
					title="Discussion of the pros and cons of each reimbursement option"
					resourceImg={reimbursementOptions}
					pageLink="/product/ichra/reimbursement-options"
				/> */}
			</ResourceCenter>
			<HraContact />
		</>
	)
}
